<template>
  <main
    class="color-primary h-100 blank-aside-js w-100"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <section class="tourism-product__gallery">
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-1">
          <img class="tourism-product__image" src="/assets/img/pool-hotel.png" alt="">
      </div>
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-2">
          <img class="tourism-product__image" src="/assets/img/hotel.png" alt="">
      </div>
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-3">
          <img class="tourism-product__image" src="/assets/img/hall.png" alt="">
      </div>
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-4">
          <img class="tourism-product__image" src="/assets/img/terrace.png" alt="">
      </div>
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-5">
          <img class="tourism-product__image" src="/assets/img/pool-croped.png" alt="">
      </div>
      <a class="link tourism-product__more-photo" href=""></a>
    </section>

    <section class="tourism-container">
      <div class="row m-0">
        <div class="col pb-0">
          <h4>{{ $t('Order Details') }}</h4>
        </div>
      </div>
      <div class="row mt-0">
        <div class="col-md-9">
          <div class="bg-white p-2 bd-radius">
            <div class="d-flex align-items-center flex-wrap mt-2">
              <h4 class="mx-2 mb-5">{{ $t('Trip Summary') }}</h4>
              <TripSummary class="mx-7 mb-5"></TripSummary>
            </div>
            <div class="row mt-2 ml-10">
              <div class="col-md-4">
                <FligntTicket></FligntTicket>
              </div>
              <div class="col-md-4">
                <FligntTicket></FligntTicket>
              </div>
              <div class="col-md-4">
                <BaggageInfo></BaggageInfo>
                <CovideNotify class="mt-7"></CovideNotify>
              </div>
            </div>
            <div class="row mt-0 mb-2">
              <div class="col-md-8 fs14 text-center">
                4 nights in Berln then <b>depart from Willy Brandt (BER)</b> 
              </div>
            </div>
          </div>
          <div class="bg-white p-5 bd-radius mt-7 p-t-15">
            <div class="row baggage-summary mt-2">
              <div class="col-md-6 cabin-baggage">
                <h4 class="text-center">
                  {{ $t('Cabin Baggage') }}
                </h4>
                <p class="text-center fs12">{{ $t('Select one option') }}</p>
                <div class="d-flex align-items-baseline mt-5">
                  <input
                    class="mx-7"
                    type="radio"
                    name="radio1"
                    checked
                  />
                  <div class="d-flex align-items-center radio-label bd-botton fs14 pb-5 w-100">
                    <div class="d-flex align-items-center baggage-title">
                      <img src="/assets/img/icons/side bag.svg" alt="">
                      <p class="mx-5"><b>{{ $t('Personal Item') }}</b></p>
                    </div>
                    <div class="size">
                      40 × 20 × 30 cm, 10 kg
                    </div>
                    <div class="price text-center">
                      <b>0₪</b>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-baseline mt-5">
                  <input
                    class="mx-7"
                    type="radio"
                    name="radio1"
                  />
                  <div class="w-100">
                    <div class="d-flex align-items-center radio-label fs14 pb-5 w-100">
                      <div class="d-flex align-items-center baggage-title">
                        <img src="/assets/img/icons/side bag.svg" alt="">
                        <p class="mx-5"><b>{{ $t('Personal Item') }}</b></p>
                      </div>
                      <div class="size">
                        40 × 20 × 30 cm, 10 kg
                      </div>
                      <div class="price text-center">
                        <b>197.45₪</b>
                      </div>
                    </div>
                    <div class="d-flex align-items-center radio-label fs14 pb-5 w-100">
                      <div class="d-flex align-items-center baggage-title">
                        <img src="/assets/img/icons/caryonbag.svg" alt="">
                        <p class="mx-5"><b>{{ $t('Cabin Baggage') }}</b></p>
                      </div>
                      <div class="size">
                        55 × 23 × 40 cm, 10 kg
                      </div>
                      <div class="price text-center">
                        <b></b>
                      </div>
                    </div>
                    <div class="d-flex align-items-center radio-label fs14 pb-5 w-100">
                      <img src="/assets/img/icons/caryonbag.svg" alt="">
                      <p class="mx-5 fs10">
                        Priority Boarding is included for free in this bundle (Wizz Air). <b class="text-decoration-underline">Learn more</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 cabin-baggage">
                <h4 class="text-center">
                  {{ $t('Checked Baggage') }}
                </h4>
                <p class="text-center fs12">{{ $t('Select one option') }}</p>
                <div class="d-flex align-items-baseline mt-5">
                  <input
                    class="mx-7"
                    type="radio"
                    name="radio2"
                    checked
                  />
                  <div class="d-flex align-items-center radio-label bd-botton fs14 pb-5 w-100">
                    <img src="/assets/img/icons/X.svg" alt="">
                    <p class="mx-5"><b>{{ $t('No checked baggage') }}</b></p>
                  </div>
                </div>
                <div class="d-flex align-items-baseline mt-5">
                  <input
                    class="mx-7"
                    type="radio"
                    name="radio2"
                  />
                  <div class="w-100">
                    <div class="d-flex align-items-center radio-label fs14 pb-5 w-100">
                      <div class="d-flex align-items-center baggage-title">
                        <img src="/assets/img/icons/checkedbag.svg" alt="">
                        <p class="mx-5"><b>20 kg Checked bag</b></p>
                      </div>
                      <div class="size">
                        90 × 43 × 75 cm, 20 kg
                      </div>
                      <div class="price text-center">
                        <b>697.45₪</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tip-section p-7 fs12 bg-blue4 mt-15">
              <b>{{ $t('Tip') }}</b> {{ $t('Travel safely by adding a checked bag instead. You can take less on board and avoid moving around the cabin.') }}
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <FlightResult></FlightResult>
          <router-link
            :to="{
              name: 'FlightOrderSummaryBooking',
            }"
          >
            <button class="primary-btn primary-btn_sm order-btn">
              {{ $t('Order') }}
            </button>
          </router-link>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import  FligntTicket from "../../../components/tourism/tradeShow/FligntTicket.vue";
import  BaggageInfo from "../../../components/tourism/tradeShow/BaggageInfo.vue";
import  CovideNotify from "../../../components/tourism/tradeShow/CovideNotify.vue";
import  TripSummary from "../../../components/tourism/tradeShow/TripSummary.vue";
import  FlightResult from "../../../components/tourism/flight/FlightResult.vue";

export default {
  name: "FlightOrderSummary",
  components: {
    FligntTicket,
    BaggageInfo,
    CovideNotify,
    TripSummary,
    FlightResult
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/tourism.scss";
.order-btn {
  margin: 0;
  margin-top: 15px;
}

.baggage-summary {
  .cabin-baggage {
    border-right: 1px solid #E7E7E8;
  }
  .bd-botton {
    border-bottom: 1px solid #c5c5c5;
  }
  .radio-label {
    .baggage-title {
      width: 40%;
    }
    .size {
      width: 40%;
    }
    .price {
      width: 20%;
    }
  }
  
}
</style>