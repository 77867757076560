<template>
  <div class="bg-white fs14 result-block bd-radius">
    <p class="text-center" v-show="isShowTitle"><b>{{ $t('Order summary') }}</b></p>
    <div class="d-flex justify-content-between align-items-center mb-7">
      <p>{{ $t("Traveling") }}</p>
      <p>3</p>
    </div>
    <div class="d-flex justify-content-between align-items-center mb-7">
      <p>{{ $t('Price per passenger') }}</p>
      <p>400 ₪</p>
    </div>
    <div class="d-flex justify-content-between align-items-center mb-7">
      <p>{{ $t('ToTAL_ORDER_PRICE') }}</p>
      <p>1,200 ₪</p>
    </div>
    <div class="d-flex justify-content-between align-items-center mb-7">
      <p>{{ $t('Cabin Baggage') }}</p>
      <p>197 ₪</p>
    </div>
    <div class="d-flex justify-content-between align-items-center mb-2">
      <p>{{ $t('Checked Baggage') }}</p>
      <p>0.00 ₪</p>
    </div>
    <div class="total-value d-flex justify-content-between align-items-center mb-5 fs16 pt-7">
      <p><b>{{ $t('Total') }} (ILS)</b></p>
      <p><b>1,397 ₪</b></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FlightResult",
  props: {
    isShowTitle: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.result-block {
  padding: 10px 20px;
}

.total-value {
  border-top: 1px solid #002036;
}
</style>